window._ = require('lodash');

try {

    window.$ = window.jQuery = require('jquery');
    require('bootstrap-sass');
    
    jQuery.fn.exists = function(){return this.length>0;}

} catch (e) {
  console.log(e);
}
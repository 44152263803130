var navScrolled = function() {

  var $window    = $(window);
  var docViewTop = 83;
  var nav        = $('.banner');
  var elemTop    = $window.scrollTop();

  if ( elemTop > docViewTop ) {
    nav.addClass('scrolled');
  } else {
    nav.removeClass('scrolled');
  }

};

navScrolled();

$( window ).scroll( function(e) {
  navScrolled();
});